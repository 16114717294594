<template>
  <div>
      <v-card color="" flat tile height="" class="pa-0" v-for="(post, index) in posts" :key="index">
           <v-row no-gutters class="blog-row-holder" v-if="post.status  === 1">
                <v-col lg="2" md="2" sm="6" xs="12" class="">
                    <v-card flat tile height="43vh"  class="mb-1" column align="center" justify="center">
                      <v-card-text class="zamu-sub-heading date-holder">
                        <span>{{ formatDate(post.created_at, 'DD') }}</span>
                        <br />

                        <span class="month-holder">{{ formatDate(post.created_at, 'MMM') }}</span>
                      </v-card-text>
                      <v-card-text class="note-holder mb-10">
                        <!-- bottom -->
                        
                          <v-icon size="38" dense dark class="">mdi-note-text-outline</v-icon>

                      </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="10" md="10" sm="6" xs="12" cols="12">
                    <v-card  flat height="43vh" tile xs12 sm12  width="100%" dark class="blog-image-holder">
                     <span class="blog-image" v-html="post.image"></span>
                    </v-card>
                </v-col>
          </v-row>
          <v-row  no-gutters class="blog-row-holder">
            <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="">
              <v-card color="" flat tile height="100%"  column class="">
                <v-card-title primary-title class="justify-left">
                        <h3 class="zamu-sub-heading clients-header blog-header mt-5 mb-3">{{ post.title }}</h3><br />
                        <h5 color="red" class="blog-sub-header my-2">
                            <!-- <v-icon size="14" :color="`${zamuGrey}`" class="">mdi-eye</v-icon> -->
                             <!-- 20 minutes read -->
                          </h5>
                        <v-card tile flat :color="`${zamuGrey}`" height="3" class="justify-left mt-2 transact-underline " width="100%">
                            <v-card-text class="transact-divider divider-yellow blog-underline"> </v-card-text>
                        </v-card>
                </v-card-title>
                <v-card-text>
                  <span v-html="post.snippet"></span>
                </v-card-text>
                <v-card-actions align="right" justify="flex-end" class="blog-buttons">
                  <v-btn-toggle >
                      <v-btn depressed dark :color="`${zamuPurple2}`" @click="singlePost(post.id)">
                        Read More
                      </v-btn>

                      <v-btn :color="`${zamuYellow}`">
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                  </v-btn-toggle>
                </v-card-actions>
                
              </v-card>
            </v-col>
          </v-row>

      </v-card>
  </div>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import moment from 'moment';


export default {
    name: 'BlogContent',
    mixins: [colorMixin],
    props: ['posts'],

    methods: {
      formatDate(date, format) {
           const formated = moment(date).format(format);
           return formated;

      },
      singlePost(id) {
        this.$router.push({ name: 'single-blog', params: { id: id } })
      },
    },

}
</script>

<style>
.blog-underline {
  width:12% !important;
}
.blog-image img {
  width: 100%;
}

</style>